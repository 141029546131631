.btn {
  width: 100%;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: var(--transition);

  background: transparent;
  border: none;

  text-transform: uppercase;
  text-decoration: none;
  font-family: var(--font);
  font-weight: 600;
  line-height: 100%;
  text-align: center;
}

/*---------------------------------ПАЛИТРА------------------------------------*/

.btn_primary {
  background: rgba(245, 128, 37, 0.1);
  box-shadow: inset 0 0 20px rgba(245, 128, 37, 0.8);

  color: #FF913C;
}

.btn_secondary {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.1);

  color: #FFFFFF;
}

/*---------------------------------РАЗМЕРЫ------------------------------------*/

.btn_medium {
  padding: 24px;
  font-size: 16px;
  @include tablet {
    padding: 12px;
  }
}

.btn_small {
  padding: 12px;
  font-size: 16px;
  @include tablet {
    padding: 5px;
  }
}
