@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
i {
  color: $init;
}
@import "_fonts";
@import "_vars";
@import "_reset";
@import "_btns";
@import "_typography";

body {
  background-color: #2B224F;
}
