@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('IBM Plex Sans'), local('IBM Plex Sans'),
  url('fonts/IBMPlexSans/IBMPlexSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('IBM Plex Sans'), local('IBM Plex Sans'),
  url('fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('IBM Plex Sans'), local('IBM Plex Sans'),
  url('fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');
}
