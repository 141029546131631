h1, h2, h3, h4 {
  font-family: var(--font-heading);
  line-height: 1em;
  margin: 0;
  color: #FFFFFF;
}
h5, h6, p {
  font-family: var(--font);
  line-height: 150%;
  margin: 0;
  color: #FFFFFF;
}

h1 {
  font-weight: 500;
  font-size: 50px;
  @include tablet {
    font-size: 30px;
  }
}
h2 {
  font-weight: 500;
  font-size: 30px;
}
h3 {
  font-weight: 500;
  font-size: 26px;
  @include tablet {
    font-size: 20px;
  }
}
h4 {
  font-weight: 600;
  font-size: 20px;
}

h5 {
  font-weight: 600;
  font-size: 16px;
}
h6 {
  font-weight: 600;
  font-size: 14px;
}

p.body1 {
  font-weight: 400;
  font-size: 24px;
  @include tablet {
    font-size: 16px;
  }
}
p.body2 {
  font-weight: 400;
  font-size: 18px;
  @include tablet {
    font-size: 14px;
  }
}
p.caption {
  font-weight: 400;
  font-size: 12px;
}
